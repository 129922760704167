
  
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f2f5;
  }

  .login-spinner {
    margin: auto;
  }
  
  .login-form {
    width: 300px;
    max-width: 300px;
  }
  
  .login-form-item {
    width: 100%;
  }
  
  .login-form-button {
    width: 150px;
  }
  
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    width: 300px;
    min-width: 300px;
  }
  
  .logo-login {
    /* width: 300px; */
    min-width: 300px;
  }
  
  .logo-text {
    font-size: 16px;
    width: max-content;
  }

  .language-radio-group {
    display: flex;
    min-width: 400px !important;
    max-width: 400px !important;
    /* align-items: center !important; */
    /* justify-items: center !important; */
    /* align-content: center !important; */
    justify-content: center !important;
  }

  .language-radio {
    min-width: 120px !important;
    max-width: 120px !important;
  }