.active-list-item {
    background-color: rgb(221, 230, 241);
  }
  
.list-item {
  padding: 10px !important;
}

.active-transcription {
  color: rgb(32, 141, 56);
}