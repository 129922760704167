.logo {
    height: 31px;
    width: 31px;
    background: rgba(255, 255, 255, 0);
  }

.main-layout-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

}

.main-layout-header {
  padding-left: 12px;
  padding-right: 12px;
}

.main-layout-header-row {
  margin: 0px !important;
  align-items: center;
  width: 100%;
}

.main-layout-header-left-col {
  display: flex;
  align-items: center;
  justify-items: left;
}

.main-layout-header-right-col {
  display: flex;
  align-items: center;
  justify-content: right;
}

.main-layout-left-group {
  display: flex;
  align-items: center;
  width: 100%;
}

.main-layout-logo-col {
  display: flex;
  align-items: center !important;
  justify-items: center !important;
  justify-content: center !important;
  align-content: center !important;
  width: 100px !important;
  height: 64px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.main-layout-logo-col-a {
  display: flex;
  align-items: center !important;
  justify-items: center !important;
  justify-content: center !important;
  align-content: center !important;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
}


.main-layout-logo-col-a:hover {
  background-color: #1677ff94;
  color: #ffffff !important;
}


.main-layout-menu-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 0px !important;

}


.main-layout-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
}

.main-layout-right-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-layout-language-icon-space {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.main-layout-language-icon {
  display: block;
  color: #A5ADB5;
  font-size: 18px;
}

.main-layout-menu .ant-menu-item:hover{
  background-color: #1677ff94 !important;
}

.main-layout-content {
  overflow: auto;
  width: 100%;
  min-height: 100%;
  height: calc(100vh - 64px - 48px);
}

.language-selector {
  width: 140px;
  margin-bottom: 0;
}

.main-layout-dropdown {
  padding-left: 12px;
  padding-right: 12px;
  transition: background 0.3s;
  cursor: pointer;
}

.main-layout-dropdown svg{
  transition: color 0.3s ease;
}

.main-layout-dropdown a{
  transition: color 0.3s ease;
}

.main-layout-dropdown:hover {
  background-color: #1677ff94;
}

.main-layout-dropdown:hover svg{
  color: #ffffff !important;
}

.main-layout-dropdown:hover a{
  color: #ffffff !important;
}


.flag-icon {
  width: 15px;
  border: 1px solid #A5ADB5;
}

.main-layout-avatar{
  background-color: #1677FF;
}



/* App.css */


.main-layout-language-radio .ant-radio-button-wrapper .ant-radio-button-wrapper-checked > span {
  accent-color: #509679 !important;
}


.ant-radio-button .ant-radio-button-checked .main-layout-language-radio {
  accent-color: #9b2d2daa !important;
}
