.summary-stat-row {
    padding: 8px;
    margin: 0 !important;
    width: 100%;
    justify-content: center;
}

.detailed-stat-row {
    padding: 8px;
    margin: 0 !important;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.detailed-stat-table {
    width: 95%;
}

.detailed-title-row {
    padding: 2px;
    margin: 0 !important;
    width: 100%;
    justify-content: center;
}

.total-title-row {
    display: flex;
    padding: 2px;
    margin: 0 !important;
    width: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.show-more-row {
    padding: 2px;
    margin: 0 !important;
    width: 100%;
    justify-content: center;
}

.show-more-button {
    color: #405ce8 !important;
    background-color: #a9a9a919;
    width: 50%;
    justify-content: center;
    font-size: 12px;
}

.show-more-button:hover {
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    color: #5175fa !important;
    background-color: #a9a9a95b;
    width: 50%;
    justify-content: center;
}
