.aichat-main-content-row {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  height: 100%;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 12px !important;
  margin: 0px;
}

.add-chat-button {
    width: 100%;
}

.active-chat-list-item {
  background-color: #d2d2d7;
}

.chat-message-item {
    padding: 10px !important;
    background-color: #b4b4b40b;
}

.chat-messages-scrollable-div {
    max-height: 70vh;
    min-height: 70vh;
    overflow: auto;
    padding: 10 0;
}


.chat-messages-loading {
    display: flex;
    min-height: 60vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
}
