.content-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0px !important;
}

.content-filter-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 6px;
    margin: 0px !important;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f5f5f5;
    padding-left: 12px;
    padding-right: 12px;
}

.content-filter-show-button-row {
    min-width: 100%;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.content-filter-show-button {
    min-width: 50%;
    color: #405ce8 !important;
    background-color: #a9a9a919;
    font-size: 12px !important;
}

.content-filter-show-button:hover {
    transition: background-color 0.3s ease;
    transition: color 0.3s ease;
    color: #5175fa !important;
    background-color: #a9a9a95b;
}

.content-row-column {
    text-align: center;
    padding: 0px !important;
}

.table-row {
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: center; */
    /* padding-top: 0px; */
    /* padding-bottom: 0px; */
    /* margin: 0px !important; */
    /* max-height: 10px !important; */
    /* height: 10px !important; */
}