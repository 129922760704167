/* src/App.css */
.layout {
    min-height: 100vh;
  }
  
  .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
  }
  
  .section {
    text-align: center;
    padding: 100px 0;
    min-height: 100vh;
    opacity: 0;
    animation: fadeIn 5s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


