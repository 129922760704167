.projects-settings-main-content-row {
  padding-left: 16px;
  padding-right: 16px;
  height: 86vh;
  overflow: hidden;
}

.left-menu {
  max-height: 86vh;
  overflow-y: auto;
  padding: 16px;
  background-color: #f0f2f5;
}

.right-content {
  height: 86vh;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
}

.project-card .ant-card-head {
  background-color: #fafafa;
  border-bottom: 1px solid #d9d9d9;
}

.list-custom {
  cursor: pointer;
  transition: background-color 0.2s;
}

.list-custom:hover {
  background: #dde6f1;
}

.custom-title {
  margin-bottom: 4px !important;
  flex-shrink: 0;
}

.set-project-transcription-settings {
  /* max-width: 800px; */
  padding-bottom: 24px;
}

.ant-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-tabs-content-holder {
  flex: 1;
  overflow-y: auto;
}

.settings-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.settings-tabs .ant-tabs-content {
  height: 100%;
  flex: 1;
}

.settings-tabs .ant-tabs-content-holder {
  height: 100%;
}

.settings-tabs .ant-tabs-content-top {
  height: 100%;
}

.tab-content-scroll {
  height: 100%;
  overflow-y: auto;
  padding-right: 16px;
}